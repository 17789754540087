import { get, pick, flatten, difference, find, isEqual } from 'lodash-es'
import appContext, { dataBindingAppContext } from './DataBindingAppContext'
import DataProvider from '../data/DataProvider'
import {
  convertFromCustomFormat,
  convertToCustomFormat,
} from '@wix/cloud-elementory-protocol'
import { PRIMARY } from '../data/sequenceType'
import * as DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import completeControllerConfigs from '../dataset-controller/completeControllerConfigs'
import { parseUrlPattern } from '../helpers/urlUtils'
import { Deferred } from '../helpers'
import { createRecordStoreService } from '../record-store'
import createControllerFactory from '../dataset-controller/controllerFactory'
import AppState from './AppState'
import {
  DataBindingLogger,
  Trace,
  Breadcrumb,
  createErrorReporting,
  createBreadcrumbReporting,
  createVerboseReporting,
} from '../logger'
export default class DataBinding {
  #dataProvider
  #dataCache
  #features
  #listenersByEvent
  #logger

  //TODO: invert
  #routerPayload
  #recordStoreCache

  constructor({
    platform,
    dataFetcher,
    dataCache,
    features,
    listenersByEvent,
    logger,
    global,

    //TODO: add to dataCache, and distinguish there which data should be saved to warmup store.
    // for now routerData schemas are also saved to the warmup data which is not oprimal
    routerReturnedData,
  }) {
    const dataBindingLogger = new DataBindingLogger(logger, global)

    dataBindingAppContext.set({
      platform,
      features,
      dataFetcher,
      appState: new AppState(),
      logger: dataBindingLogger,
      errorReporting: createErrorReporting(dataBindingLogger),
      breadcrumbReporting: createBreadcrumbReporting(dataBindingLogger),
      verboseReporting: createVerboseReporting(dataBindingLogger),
    })

    this.#dataProvider = new DataProvider()
    this.#dataCache = dataCache
    this.#features = features
    this.#logger = dataBindingLogger

    this.#routerPayload = routerReturnedData
    this.#recordStoreCache = {}
    this.#listenersByEvent = listenersByEvent
  }

  initializeDatasets({
    //TODO: temp interface
    rawControllerConfigs,
  }) {
    try {
      return this.#logger.log(
        new Trace('databinding/createControllers', () =>
          this.#initializeDatasets({ rawControllerConfigs }),
        ),
      )
    } catch (e) {
      this.#logger.logError(e, 'Datasets initialisation failed')
      return []
    }
  }

  #initializeDatasets({
    //TODO: temp interface
    rawControllerConfigs,
  }) {
    const {
      platform: {
        settings: {
          mode: { name: modeName, csr, ssr },
          env: { livePreview },
        },
      },
    } = appContext

    const controllerConfigs = completeControllerConfigs(
      rawControllerConfigs,
      this.#routerPayload,
    )
    const updatedDatasetIds = this.#updateDatasetConfigsState(controllerConfigs)
    const { routerData, dynamicPagesData } = this.#routerPayload
      ? extractRouterPayload(
          this.#routerPayload,
          convertFromCustomFormat,
          controllerConfigs,
        )
      : {}
    const warmupDataIsEnabled = this.#features.warmupData

    const fetchingAllDatasetsData = []
    const renderingControllers = []
    const {
      resolve: renderDeferredControllers,
      promise: renderingRegularControllers,
    } = new Deferred()

    const cachedSchemas =
      warmupDataIsEnabled && csr ? this.#dataCache.get('schemas') : undefined

    const schemasLoading = this.#logger.log(
      new Trace('databinding/loadSchemas', () =>
        this.#dataProvider
          .loadSchemas(
            getUniqueCollectionIds(controllerConfigs, this.#routerPayload),
            {
              ...cachedSchemas,
              ...this.#routerPayload?.schemas,
            },
          )
          .then(
            schemas =>
              warmupDataIsEnabled &&
              ssr &&
              this.#dataCache.set('schemas', schemas),
          ),
      ),
    )

    const cachedStore =
      csr &&
      warmupDataIsEnabled &&
      convertFromCache(this.#dataCache.get('dataStore'))

    if (cachedStore) {
      this.#dataProvider.setStore(cachedStore)
    }

    this.#dataProvider.setStore(routerData) //TODO: consider moving router data to cache
    this.#dataProvider.createBulkRequest(
      this.#getBulkRequestConfigs(controllerConfigs, updatedDatasetIds),
    )

    const controllers = controllerConfigs.map(
      ({
        type,
        config,
        connections,
        $w,
        compId: datasetId,
        livePreviewOptions: {
          shouldFetchData: dataIsInvalidated,
          compsIdsToReset: updatedCompIds = [],
        } = {},
      }) => {
        const { datasetIsRouter, datasetIsDeferred } =
          config.datasetStaticConfig
        this.#logger.log(
          new Breadcrumb({
            category: 'createControllers',
            message: 'warmup data contents',
            data: {
              datasetId,
              datasetType: type,
              mode: modeName,
              warmupData: Boolean(cachedStore),
            },
          }),
        )

        const recordStoreService = createRecordStoreService({
          primaryDatasetId: datasetId,
          recordStoreCache: this.#recordStoreCache,
          refreshStoreCache: dataIsInvalidated,
          warmupStore: undefined,
          dataProvider: this.#dataProvider,
          controllerConfig: config,
        })

        const {
          promise: fetchingDatasetData,
          resolve: markDatasetDataFetched,
        } = new Deferred()
        if (!datasetIsRouter && !datasetIsDeferred) {
          // But router will be in dataStore anyway. Filter out?
          fetchingAllDatasetsData.push(fetchingDatasetData)
        }

        const {
          promise: renderingController,
          resolve: markControllerAsRendered,
        } = new Deferred()
        renderingControllers.push(renderingController)

        const controllerFactory = createControllerFactory(this.#logger, {
          $w,
          controllerConfig: config,
          datasetType: type,
          connections,
          recordStoreService,
          dataProvider: this.#dataProvider,
          firePlatformEvent: $w.fireEvent, //it's just a dispatcher. don't see any reason to wrap it with error handler
          dynamicPagesData: datasetIsRouter ? dynamicPagesData : undefined,
          datasetId,
          handshakes: [],
          schemasLoading,
          listenersByEvent: this.#listenersByEvent,
          updatedCompIds,
          markControllerAsRendered,
          markDatasetDataFetched,
          renderingRegularControllers,
          modeIsLivePreview: livePreview,
          modeIsSSR: ssr,
          useLowerCaseDynamicPageUrl: get(this.#routerPayload, [
            'config',
            'dataset',
            'lowercase',
          ]),
        })

        const datasetController = extractPlatformControllerAPI(
          controllerFactory.createPrimaryController(),
        )
        return Promise.resolve(datasetController)
      },
    )

    if (ssr && warmupDataIsEnabled && fetchingAllDatasetsData.length) {
      Promise.all(fetchingAllDatasetsData).then(() => {
        this.#dataCache.set(
          'dataStore',
          convertToCache(this.#dataProvider.getStore()),
        )
      })
    }
    Promise.all(renderingControllers).then(renderDeferredControllers)

    return controllers
  }

  #updateDatasetConfigsState(datasetConfigs) {
    const { appState } = appContext
    return datasetConfigs.reduce(
      (updatedDatasetIds, { compId: datasetId, config: { dataset } }) => {
        const datasetConfigState = appState.datasetConfigs.get(datasetId)
        if (datasetConfigState && !isEqual(datasetConfigState, dataset)) {
          updatedDatasetIds.push(datasetId)
        }
        appState.datasetConfigs.set(datasetId, dataset)

        return updatedDatasetIds
      },
      [],
    )
  }

  #getBulkRequestConfigs(datasetConfigs, updatedDatasetIds) {
    return datasetConfigs.reduce(
      (
        acc,
        {
          compId: datasetId,
          config: {
            datasetStaticConfig: { sequenceType },
          },
          livePreviewOptions: { shouldFetchData } = {},
        },
      ) =>
        sequenceType === PRIMARY
          ? [
              ...acc,
              {
                id: datasetId,
                refresh:
                  shouldFetchData || updatedDatasetIds.includes(datasetId),
              },
            ]
          : acc,
      [],
    )
  }
}

const getUniqueCollectionIds = (datasetConfigs, routerData) => {
  const uniqueCollectionIds = datasetConfigs.reduce(
    (
      uniqueIds,
      {
        config: {
          dataset: { collectionName },
        },
      },
    ) => (collectionName ? uniqueIds.add(collectionName) : uniqueIds),
    new Set(),
  )

  if (routerData?.schemas) {
    for (const collectionId of Object.keys(routerData.schemas)) {
      uniqueCollectionIds.add(collectionId)
    }
  }

  return [...uniqueCollectionIds]
}

const extractRouterPayload = (payload, parser, controllerConfigs) => {
  const routerDataset = find(controllerConfigs, {
    type: DATASET_TYPES.ROUTER_DATASET,
  })
  const datasetId = routerDataset && routerDataset.compId
  if (!datasetId) return {}
  const collectionName = get(routerDataset, 'config.dataset.collectionName')

  const {
    dynamicUrl,
    userDefinedFilter,
    items = [],
    totalCount,
    config,
  } = payload
  const parsedItems = parser(items)
  const record = parsedItems[0]
  const datasetSort = get(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields),
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]

  const dynamicUrlPatternFieldsValues =
    extractDynamicUrlPatternFieldsValuesFromRecord(
      dynamicUrl,
      record,
      sortFields,
      patternFields,
    )

  return {
    routerData: {
      recordsInfoByDataset: {
        [datasetId]: {
          itemIds: parsedItems.map(({ _id }) => _id),
          totalCount,
        },
      },
      recordsByCollection: {
        [collectionName]: parsedItems.reduce(
          (acc, record) => ({
            ...acc,
            [record._id]: record,
          }),
          {},
        ),
      },
    },
    dynamicPagesData: {
      dynamicUrl,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues,
      sort,
      sortFields,
      patternFields,
    },
  }
}

const getDatasetSortFields = sort =>
  flatten(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {},
  )

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  dynamicUrl,
  record,
  sortFields,
  patternFields,
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick(record, sortAndPatternFields) : null
}

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose,
})

const createConverter = convert => dataStore => {
  // TODO: change date format to ISO string and this conversion won't be needed
  if (dataStore) {
    return {
      ...dataStore,
      recordsByCollection: Object.entries(dataStore.recordsByCollection).reduce(
        (acc, [collection, recordsById]) => {
          acc[collection] = convert(recordsById)
          return acc
        },
        {},
      ),
    }
  }
}
const convertToCache = createConverter(convertToCustomFormat)
const convertFromCache = createConverter(convertFromCustomFormat)
